import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, TextField, Box } from '@mui/material';

function Home() {
  const navigate = useNavigate();

  const [orderNumber, setOrderNumber] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (orderNumber.length > 0 && /^\d+$/.test(orderNumber)) {
      navigate(`/giardi/order-status/${orderNumber}`);
    }
  };
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div
        style={{
          height: '40vh',
          width: '100%',
          background: `url(${require('../assets/img_hero_1.jpg')})`,
          backgroundColor: 'white',
          backgroundSize: '70%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        bgcolor="#ebf5ff"
        sx={{
          flexGrow: 1,
          paddingTop: '20px',
          borderRadius: '10px',
          marginTop: '-20px',
          justifyContent: 'flex-start'
        }}
      >
        <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px', width: '90%' }}>Inserisci il tuo numero d'ordine per sapere quando sarà pronto per essere ritirato!</Typography>
        <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '20px', width: '90%', fontStyle: 'italic' }}>Insert your order number and check when you can pick it up!</Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Numero ordine"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={orderNumber}
            onChange={(event) => setOrderNumber(event.target.value)}
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          <Button variant="contained" type="submit" fullWidth>
            Conferma
          </Button>
        </form>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        bottom="0"
        width="100%"
        height="60px"
        bgcolor="#ebf5ff"
      >
        <Typography variant="body1" sx={{ marginRight: '5px', color: 'grey' }}>
          powered by
        </Typography>
        <img src={require('../assets/img_logo.png')} alt="Logo" height="16px" />
      </Box>
    </div>
  );
}

export default Home;
