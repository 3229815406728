import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import OrderStatus from './components/OrderStatus';
import OrderManagement from './components/OrderManagement';
import OrderHistory from './components/OrderHistory';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#182d42',
    },
    secondary: {
      main: '#1a1a1a',
    },
  },
  typography: {
    title: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    subtitle: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/giardi/" element={<Home />} />
          <Route path="/giardi/order-status/:orderNumber" element={<OrderStatus />} />
          <Route path="/giardi/order-manager" element={<OrderManagement />} />
          <Route path="/giardi/order-history" element={<OrderHistory />} />
          </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
