import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, TextField, Button, Box, Rating } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function OrderStatus() {
  const { orderNumber } = useParams();
  const [orderReady, setOrderReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [email, setEmail] = useState('');
  const [reviewSent, setReviewSent] = useState(false);

  const fetchOrderStatus = async () => {
    try {
      const response = await axios.post(
        'https://gjoe6q3fvj.execute-api.eu-west-1.amazonaws.com/test/queue/',
        {
          username: 'giardi',
          orderId: orderNumber,
        }
      );
      console.log(response.data);
      setOrderReady(response.data.orderReady);
      if (response.data.rating || response.data.comments || response.data.email) {
        setReviewSent(true);
      }
      setLoading(false);
    } catch (error) {
      console.log('Error:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderStatus();
    const timer = setInterval(fetchOrderStatus, 10000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);

  const updateOrderReview = async () => {
    try {
      const response = await axios.post(
        'https://gjoe6q3fvj.execute-api.eu-west-1.amazonaws.com/test/queue/',
        {
          username: 'giardi',
          orderId: orderNumber,
          rating: rating || undefined,
          comments: comment || undefined,
          email: email || undefined,
        }
      );
      console.log(response.data);
      setReviewSent(true);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleRatingChange = (newValue) => {
    setRating(newValue);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleReviewSubmit = () => {
    updateOrderReview();
  };

  return (
    <div>
      {loading ? (
        <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div
              style={{
                height: '40vh',
                width: '100%',
                background: `url(${require('../assets/img_hero_1.jpg')})`,
                backgroundColor: 'white',
                backgroundSize: '70%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              paddingTop="20px"
              bgcolor="#ebf5ff"
              sx={{ borderRadius: '10px', marginTop: '-20px' }}
            >
              {orderReady ? (
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="80%">
                  <Typography variant="subtitle" sx={{ textAlign: 'center', width: '90%' }}>Il tuo ordine è pronto. Facci sapere come siamo andati!</Typography>
                  <Typography variant="subtitle" sx={{ textAlign: 'center', width: '90%', fontStyle: 'italic', marginTop: '40px' }}>Your food is ready. We would love to hear from you!</Typography>
                  <Typography variant="subtitle" sx={{ marginBottom: '40px', marginTop: '20px', color: 'grey' }}>#{orderNumber}</Typography>
                  {!reviewSent && (
                    <Box>
                      <Rating
                        name="rating"
                        value={rating}
                        onChange={(event, newValue) => handleRatingChange(newValue)}
                        size="large"
                        sx={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                      />
                      <TextField
                        label="Commenti"
                        type="text"
                        value={comment}
                        onChange={handleCommentChange}
                        fullWidth
                        multiline
                        rows={2}
                        sx={{ marginBottom: '10px' }}
                      />
                      <TextField
                        label="E-mail"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        fullWidth
                        sx={{ marginBottom: '10px' }}
                      />
                      <Button variant="contained" onClick={handleReviewSubmit} fullWidth>
                        Feedback
                      </Button>
                    </Box>
                  )}
                  {reviewSent && <Typography variant="body1">Feedback inviato</Typography>}
                  {reviewSent && <Typography variant="body1" fontStyle="italic">Feedback received</Typography>}
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <Typography variant="subtitle" sx={{ textAlign: 'center', width: '90%', marginBottom: '20px' }}>I nostri Chef stanno preparando il tuo ordine...</Typography>
                  <Typography variant="subtitle" sx={{ textAlign: 'center', width: '90%', fontStyle: 'italic' }}>Our Chefs are doing what they love most...</Typography>
                  <Typography variant="subtitle" sx={{ marginBottom: '40px', marginTop: '20px', color: 'grey' }}>#{orderNumber}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                  </Box>
                  <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '40px', width: '90%' }}>Tieni d'occhio questa pagina per sapere quando sarà pronto il tuo ordine!</Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px', width: '90%', fontStyle: 'italic' }}>Keep an eye on this page to know when your order is ready!</Typography>
                </Box>
              )}
            </Box>
          </div>
          {reviewSent &&
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            bottom="0"
            width="100%"
            height="60px"
            bgcolor="#ebf5ff"
            sx={{ borderRadius: '10px 10px 0 0' }}
          >
            <Typography variant="body1" sx={{ marginRight: '5px', color: 'grey' }}>
              powered by
            </Typography>
            <img src={require('../assets/img_logo.png')} alt="Logo" height="16px" />
          </Box>}
        </>
      )}
    </div>
  );
}

export default OrderStatus;
