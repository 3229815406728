import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography, Card, CardContent, Pagination, Rating, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

function OrderHistory() {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const navigate = useNavigate();

  // API request
  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await axios.post(
          'https://gjoe6q3fvj.execute-api.eu-west-1.amazonaws.com/test/queue/',
          {
            orderId: 'false',
            username: 'giardi'
          }
        );
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.log('Error:', error);
        setLoading(false);
      }
    };

    fetchOrderHistory();
  }, []);

  // Pagination
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentOrders = orders.slice(firstIndex, lastIndex);
  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // App bar
  const handleHomeButtonClick = () => {
    navigate(`/giardi/order-manager`);
  };  

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
        <AppBar position="static" sx={{ marginBottom: "20px" }}>
          <Toolbar>
            <Typography variant="subtitle" component="div" sx={{ flexGrow: 1 }}>
              Storico ordini
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleHomeButtonClick}>
              <HomeIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {currentOrders.map((order) => (
          <Card key={order.orderId} variant="outlined" sx={{ margin: '1rem' }}>
            <CardContent>
              <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', color: 'grey' }}>
                  #{order.orderId}
                </Typography>
                {order.email && (
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ marginLeft: '0.5rem' }}
                  >
                    {order.email}
                  </Typography>
                )}
              </Typography>
              {order.rating && (
                <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
                  <Rating name="rating" value={order.rating} readOnly />
                </Typography>
              )}
              {order.comments && (
                <Typography variant="body1" sx={{ marginTop: '0.5rem', fontStyle: 'italic' }}>
                  {order.comments}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))}
        <Pagination
          count={Math.ceil(orders.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          shape="rounded"
          sx={{ marginBottom: '20px' }}
        />
        </>
      )}
    </Box>
  );
}

export default OrderHistory;
