import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography, Button, TextField, AppBar, Toolbar, IconButton, Box, Alert } from '@mui/material';
import { History as HistoryIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

function OrderManagement() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [orderId, setOrderId] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        'https://gjoe6q3fvj.execute-api.eu-west-1.amazonaws.com/test/queue/',
        {
          username: 'giardi',
          orderId: orderNumber,
          orderReady: true,
        }
      );
      console.log(response.data);
      if (response.data.orderReady) {
        setOrderId(response.data.orderId);
        setSuccessAlert(true);
        setOrderNumber('');
        setLoading(false);
      } else {
        setErrorAlert(true);
        setLoading(false);
      }
    } catch (error) {
      console.log('Error:', error);
      setErrorAlert(true);
      setLoading(false);
    }
  };

  const handleOrderNumberChange = (event) => {
    setOrderNumber(event.target.value);
  };

  const handleHistory = () => {
    navigate(`/giardi/order-history`);
  };

  const handleAlertClose = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
        <AppBar position="static" sx={{ marginBottom: '20px' }}>
          <Toolbar>
            <Typography variant="subtitle" component="div" sx={{ flexGrow: 1 }}>
              Pannello di controllo
            </Typography>
            <IconButton color="inherit" onClick={handleHistory}>
              <HistoryIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <form onSubmit={handleSubmit}>
            <TextField
              label="Numero ordine"
              type="tel"
              value={orderNumber}
              onChange={handleOrderNumberChange}
              fullWidth
              sx={{ marginBottom: '10px' }}
            />
            <Button variant="contained" type="submit" fullWidth>
              Pronto per ritiro
            </Button>
          </form>
        </Box>
        {successAlert && (
          <Alert severity="success" onClose={handleAlertClose} sx={{ margin: '20px auto', width: "75%" }}>
            #{orderId} - Ordine completato
          </Alert>
        )}
        {errorAlert && (
          <Alert severity="error" onClose={handleAlertClose} sx={{ margin: '20px auto', width: "75%" }}>
            Si è verificato un errore nel completare l'ordine - si prega di riprovare
          </Alert>
        )}
        </>
      )}
    </Box>
  );
}

export default OrderManagement;
